import React from "react";
import "./LoadingScreen.css";

interface LoadingScreenProps {
  loading: boolean;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ loading }) => {
  return (
    <div
      id="loading-screen"
      className="loading-screen"
      style={{ display: loading ? "flex" : "none" }}
    >
      <div className="loader"></div>
      <p>Proszę czekać...</p>
    </div>
  );
};

export default LoadingScreen;
