import { useState, useEffect, useCallback } from "react";
import Cropper from "cropperjs";

interface UseFormProps {
  user: string | null;
  orderId: string | null;
  productId: string | null;
}

export const useForm = ({ user, orderId, productId }: UseFormProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = 4;
  const [cropper, setCropper] = useState<Cropper | null>(null);
  const [maxFileSize] = useState(1 * 1024 * 1024); // 1MB limit for files
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [thankYou, setThankYou] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  useEffect(() => {
    if (!user || !orderId) {
      setErrorMessage("Brak danych użytkownika.");
    }
  }, [user, orderId]);

  const nextPage = useCallback(() => {
    if (validatePage(currentPage)) {
      if (currentPage < totalPages - 1) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    } else {
      const firstErrorField = document.querySelector(
        `#page-${currentPage} .error`
      );
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [currentPage]);

  const prevPage = useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [currentPage]);

  const validatePage = (pageIndex: number): boolean => {
    // Add page-specific validation here if needed
    return true;
  };

  const submitForm = (values: Record<string, any>) => {
    setLoading(true);
    const formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (imagePreview) {
      const blobBin = atob(imagePreview.split(",")[1]);
      const array = [];
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      const file = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
      formData.append("cropped_image", file, "cropped_image.jpg");
    }

    fetch("https://hook.eu2.make.com/tdpd46chp2i6vkjx9tp5lumq87wfijsp", {
      // fetch("", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then((data) => {
        setThankYou(true);
      })
      .catch((error) => {
        console.error("Wystąpił błąd podczas wysyłania formularza:", error);
        setErrorMessage("Wystąpił błąd podczas wysyłania formularza.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cropImage = useCallback(() => {
    if (!cropper) return;

    const canvas = cropper.getCroppedCanvas();
    if (canvas) {
      const croppedImage = canvas.toDataURL("image/jpeg");
      setImagePreview(croppedImage);
    }
  }, [cropper]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      if (file.size > maxFileSize) {
        alert(`Plik ${file.name} jest za duży. Maksymalny rozmiar to 1 MB.`);
        e.target.value = "";
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return {
    currentPage,
    totalPages,
    errorMessage,
    loading,
    thankYou,
    imagePreview,
    nextPage,
    prevPage,
    handleFileChange,
    setCropper,
    cropImage,
    submitForm,
  };
};
