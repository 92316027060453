import React from "react";
import "./Header.css";

const Header: React.FC = () => {
  return (
    <header className="main-header">
      <div className="header-content">
        <a className="logo" href="/">
          <img
            src="https://swietokapitalizmu.pl/assets/img/logo-dark.svg"
            alt="Święto Kapitalizmu"
          />
        </a>
      </div>
    </header>
  );
};

export default Header;
