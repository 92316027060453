import React, { useEffect, useRef, useState } from "react";
import Cropper from "cropperjs";
import { useFormikContext, Field } from "formik";
import "cropperjs/dist/cropper.css";
import FormGroup from "./FormGroup";
import "./FormPages.css";
import CustomModal from "../../CustomModal/CustomModal";

interface FormPageOneProps {
  imagePreview: string | null;
  onNext: () => void;
  onPrev: () => void;
  cropImage: () => void;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setCropper: (cropper: Cropper | null) => void;
}

const FormPageOne: React.FC<FormPageOneProps> = ({
  imagePreview,
  onNext,
  onPrev,
  cropImage,
  handleFileChange,
  setCropper,
}) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const { setFieldValue } = useFormikContext<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    let cropper: Cropper | null = null;

    if (imageRef.current && imagePreview) {
      cropper = new Cropper(imageRef.current, {
        aspectRatio: 1,
        viewMode: 1,
        autoCropArea: 1,
        ready: () => {
          setCropper(cropper);
        },
      });
    }

    return () => {
      if (cropper) {
        cropper.destroy();
      }
    };
  }, [imagePreview, setCropper]);

  const handleCropImage = () => {
    cropImage();
    handleOpenModal();

    const timeoutId = setTimeout(() => {
      handleCloseModal();
    }, 3000);

    return () => clearTimeout(timeoutId);
  };

  return (
    <div className="form-page" id="page-1">
      <h2>O Tobie:</h2>

      <FormGroup
        label="*Imię"
        id="first_name"
        name="first_name"
        type="text"
        placeholder="Imię"
      />
      <FormGroup
        label="Nazwisko"
        id="last_name"
        name="last_name"
        type="text"
        placeholder="Nazwisko"
      />
      <FormGroup
        label="Mail"
        id="email"
        name="email"
        type="email"
        placeholder="E-mail"
      />
      <FormGroup
        label="Telefon"
        id="phone"
        name="phone"
        type="tel"
        placeholder="Numer telefonu"
      />
      <FormGroup
        label="Miasto"
        id="city"
        name="city"
        type="text"
        placeholder="Miasto"
      />
      <FormGroup
        label="Zdjęcie uczestnika"
        id="participant_photo"
        name="participant_photo"
        type="file"
        accept="image/*"
        onFileChange={(e) => {
          handleFileChange(e);
          setFieldValue("participant_photo", e.target.files?.[0]);
        }}
      />

      {imagePreview && (
        <div>
          <img
            src={imagePreview}
            alt="Preview"
            ref={imageRef}
            style={{ maxWidth: "100%" }}
          />
          <div className="button-group">
            <button
              type="button"
              onClick={handleCropImage}
              className="button button--primary"
            >
              Przytnij
            </button>
          </div>
        </div>
      )}

      <CustomModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Gotowe"
      >
        <p className="cropper-modal-content">
          Zdjęcie zostało pomyślnie przycięte
        </p>
      </CustomModal>

      <FormGroup
        label="Doświadczenie w biznesie"
        id="experience"
        name="experience"
        type="select"
        options={[
          { value: "", label: "Wybierz" },
          { value: "<2 lata", label: "do 2 lat" },
          { value: "2-5 lat", label: "2-5 lat" },
          { value: "5-10 lat", label: "5-10 lat" },
          { value: ">10 lat", label: "> 10 lat" },
        ]}
      />
      <FormGroup
        label="Wiek"
        id="age"
        name="age"
        type="select"
        options={[
          { value: "", label: "Wybierz" },
          { value: "<30", label: "do 30 lat" },
          { value: "30-40", label: "30-40 lat" },
          { value: "40-50", label: "40-50 lat" },
          { value: ">50", label: "> 50 lat" },
        ]}
      />
      <FormGroup
        label="Płeć"
        id="gender"
        name="gender"
        type="select"
        options={[
          { value: "", label: "Wybierz" },
          { value: "female", label: "Kobieta" },
          { value: "male", label: "Mężczyzna" },
        ]}
      />
      <FormGroup
        label="Profil Instagram"
        id="instagram"
        name="instagram"
        type="text"
        placeholder="Twój profil na Instagramie"
      />
      <FormGroup
        label="Profil LinkedIn"
        id="linkedin"
        name="linkedin"
        type="text"
        placeholder="Twój profil na LinkedIn"
      />
      <div className="form-group">
        <div className="checkbox-wrapper">
          <Field
            type="checkbox"
            id="profile_visibility"
            name="profile_visibility"
            className="profile_visibility"
          />
          <label htmlFor="profile_visibility">
            Chcę, aby mój profil razem z logiem firmy, zdjęciem, nazwą firmy i
            informacją o rocznym przychodzie był widoczny publicznie na stronie
            Święta Kapitalizmu.
          </label>
        </div>
      </div>

      <div className="button-group">
        <button
          type="button"
          className="button button--secondary"
          onClick={onPrev}
        >
          Wstecz
        </button>
        <button
          type="button"
          className="button button--primary"
          onClick={onNext}
        >
          Następny
        </button>
      </div>
    </div>
  );
};

export default FormPageOne;
