import React from "react";
import { useField } from "formik";
import "./FormGroup.css";

interface FormGroupProps {
  label: string;
  id: string;
  name: string;
  type?: string;
  placeholder?: string;
  accept?: string;
  options?: { value: string; label: string }[];
  onFileChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormGroup: React.FC<FormGroupProps> = ({
  label,
  id,
  name,
  type = "text",
  placeholder,
  accept,
  options,
  onFileChange,
}) => {
  const [field, meta] = useField(name);

  return (
    <div
      className={`form-group ${meta.touched && meta.error ? "has-error" : ""}`}
    >
      <label htmlFor={id}>{label}</label>
      <div className="input-container">
        {type === "select" ? (
          <select className="form-control" {...field} id={id}>
            {options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={type}
            className="form-control"
            id={id}
            placeholder={placeholder}
            accept={accept}
            {...field}
            value={type === "file" ? undefined : field.value || ""}
            onChange={(e) => {
              field.onChange(e);
              if (type === "file" && onFileChange) onFileChange(e);
            }}
          />
        )}

        {meta.touched && meta.error && <span className="error-icon"></span>}
      </div>
      {meta.touched && meta.error ? (
        <div className="validation-response">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default FormGroup;
