/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-credits">
          Automated with fun by{" "}
          <a href="https://implemo.pl/">
            <img
              src="https://implemo.pl/wp-content/uploads/2023/11/logo-implemo.svg"
              alt="implemo"
            />
          </a>
        </div>
        <div className="footer-info">
          <span>© 2024 Święto Kapitalizmu. All rights reserved.</span>
          <br />
          <a href="#">Privacy Policy</a>
          <a href="#">Terms of Service</a>
        </div>
        <div className="footer-logo">
          <img
            src="https://swietokapitalizmu.pl/assets/img/logo-dark.svg"
            alt="Święto Kapitalizmu"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
