import React from "react";
import "./FormPages.css";
interface FormPageZeroProps {
  onStart: () => void;
}

const FormPageZero: React.FC<FormPageZeroProps> = ({ onStart }) => {
  return (
    <div className="form-page" id="page-0">
      <h2>Poznajmy się</h2>
      <p style={{ textAlign: "justify" }}>
        Cieszymy się, że weźmiesz udział w Święcie Kapitalizmu. Chcemy się
        lepiej poznać. Powiedz nam więcej o Tobie, swoich biznesach i napisz
        czego szukasz (np. jakich klientów lub jakich dostawców). Udzielone
        odpowiedzi pozwolą nam lepiej wykorzystać potencjał networkingowy Święta
        Kapitalizmu i połączyć Cię z osobami, których możesz szukać albo które
        mogą szukać tego co oferuje Twój biznes.
      </p>
      <div className="form-group">
        <button
          type="button"
          id="start-button"
          className="button button--primary"
          onClick={onStart}
        >
          Zacznij
        </button>
      </div>
    </div>
  );
};

export default FormPageZero;
