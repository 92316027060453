import React from "react";
import { Formik, Form, Field } from "formik";
import { initialValues } from "./formInitialValues";
import { validationSchema } from "./formValidationSchema";
import "./Ankieta.css";
import LoadingScreen from "./utils/LoadingScreen";
import FormPageOne from "./FormPages/FormPageOne";
import FormPageThree from "./FormPages/FormPageThree";
import FormPageTwo from "./FormPages/FormPageTwo";
import FormPageZero from "./FormPages/FormPageZero";
import { useForm } from "./useForm";

const Ankieta: React.FC<{
  user: string | null;
  orderId: string | null;
  productId: string | null;
}> = ({ user, orderId, productId }) => {
  const {
    currentPage,
    errorMessage,
    loading,
    thankYou,
    nextPage,
    prevPage,
    handleFileChange,
    submitForm,
    setCropper,
    cropImage,
    imagePreview,
  } = useForm({ user, orderId, productId });

  if (loading) return <LoadingScreen loading={loading} />;
  if (thankYou)
    return (
      <div className="thank-you-message">Thank you for your submission!</div>
    );
  if (errorMessage) return <div className="error-message">{errorMessage}</div>;

  return (
    <div className="form-container">
      <Formik
        initialValues={{ ...initialValues, user, orderId, productId }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          submitForm(values);
          setSubmitting(false);
        }}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form
            id="ankieta-uczestnikow"
            encType="multipart/form-data"
            className="ankieta-uczestnikow"
          >
            <div id="form-pytania" className="form-pytania">
              {currentPage === 0 && <FormPageZero onStart={nextPage} />}
              {currentPage === 1 && (
                <FormPageOne
                  onNext={nextPage}
                  onPrev={prevPage}
                  cropImage={cropImage}
                  imagePreview={imagePreview}
                  handleFileChange={(
                    e: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    const file = e.currentTarget.files
                      ? e.currentTarget.files[0]
                      : null;
                    setFieldValue("file", file);
                    handleFileChange(e);
                  }}
                  setCropper={setCropper}
                />
              )}
              {currentPage === 2 && (
                <FormPageTwo onNext={nextPage} onPrev={prevPage} />
              )}
              {currentPage === 3 && (
                <FormPageThree onPrev={prevPage} isSubmitting={isSubmitting} />
              )}
            </div>
            <Field type="hidden" name="user" value={user} />
            <Field type="hidden" name="orderId" value={orderId} />
            <Field type="hidden" name="productId" value={productId} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Ankieta;
