interface FormValues {
  user: string;
  orderId: string;
  productId: string;
  file: File | null;
  otherFields: string;
  participant_photo: File | null;
  experience: string;
  age: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  city: string;
  gender: string;
  company_name: string;
  company_logo: File | null;
  industry: string;
  business_type: string;
  employees: string;
  annual_revenue: string;
  client_looking_for: string;
  supplier_looking_for: string;
}

export const initialValues: FormValues = {
  user: "",
  orderId: "",
  productId: "",
  file: null,
  otherFields: "",
  participant_photo: null,
  experience: "",
  age: "",
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  city: "",
  gender: "",
  company_name: "",
  company_logo: null,
  industry: "",
  business_type: "",
  employees: "",
  annual_revenue: "",
  client_looking_for: "",
  supplier_looking_for: "",
};
