import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("Imię jest wymagane")
    .min(2, "Imię musi mieć co najmniej 2 znaki"),
  last_name: Yup.string()
    .required("Nazwisko jest wymagane")
    .min(2, "Nazwisko musi mieć co najmniej 2 znaki"),
  email: Yup.string()
    .email("Niepoprawny format e-mail")
    .required("E-mail jest wymagany"),
  phone: Yup.string()
    .required("Numer telefonu jest wymagany")
    .matches(/^\d{9,12}$/, "Numer telefonu musi mieć od 9 do 12 cyfr"),
  city: Yup.string().required("Miasto jest wymagane"),
  gender: Yup.string().required("Płeć jest wymagana"),
  company_name: Yup.string().required("Nazwa firmy jest wymagana"),
  industry: Yup.string().required("Branża jest wymagana"),
  business_type: Yup.string().required("Typ działalności jest wymagany"),
  employees: Yup.string().required("Zatrudnienie jest wymagane"),
  annual_revenue: Yup.string().required("Przychód roczny jest wymagany"),
  client_looking_for: Yup.string().required(
    "Musisz określić, kogo szukasz jako klienta"
  ),
  supplier_looking_for: Yup.string().required(
    "Musisz określić, kogo szukasz jako dostawcę"
  ),
  experience: Yup.string().required("Doświadczenie w biznesie jest wymagane"),
  age: Yup.string().required("Wiek jest wymagany"),
  participant_photo: Yup.mixed()
    .required("Zdjęcie uczestnika jest wymagane")
    .nullable(),
  company_logo: Yup.mixed().required("Logo firmy jest wymagane").nullable(),
});
