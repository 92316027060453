import React from "react";
import { useFormikContext } from "formik";
import FormGroup from "./FormGroup";
import "./FormPages.css";

interface FormPageTwoProps {
  onNext: () => void;
  onPrev: () => void;
}

const FormPageTwo: React.FC<FormPageTwoProps> = ({ onNext, onPrev }) => {
  const { setFieldValue } = useFormikContext<any>();

  return (
    <div className="form-page" id="page-2">
      <h2>O biznesie:</h2>

      <FormGroup
        label="Nazwa firmy"
        id="company_name"
        name="company_name"
        type="text"
      />
      <FormGroup
        label="Logo firmy"
        id="company_logo"
        name="company_logo"
        type="file"
        accept="image/*"
        onFileChange={(e) => setFieldValue("company_logo", e.target.files?.[0])}
      />
      <FormGroup label="Branża" id="industry" name="industry" type="text" />
      <FormGroup
        label="Charakter działalności"
        id="business_type"
        name="business_type"
        type="select"
        options={[
          { value: "", label: "Wybierz" },
          { value: "B2B", label: "Firma B2B" },
          { value: "ecommerce", label: "E-commerce" },
          { value: "B2C", label: "Usługi dla B2C" },
          { value: "production", label: "Produkcja" },
          { value: "local", label: "Biznes lokalny" },
        ]}
      />
      <FormGroup
        label="Zatrudniam"
        id="employees"
        name="employees"
        type="select"
        options={[
          { value: "", label: "Wybierz" },
          { value: "<10", label: "do 10 pracowników" },
          { value: "10-25", label: "10-25 pracowników" },
          { value: "25-50", label: "25-50 pracowników" },
          { value: "50+", label: "50+ pracowników" },
        ]}
      />
      <FormGroup
        label="Przychód roczny"
        id="annual_revenue"
        name="annual_revenue"
        type="select"
        options={[
          { value: "", label: "Wybierz" },
          { value: "<1mln", label: "do 1 mln" },
          { value: "1-5mln", label: "1-5 mln" },
          { value: "5-10mln", label: "5-10 mln" },
          { value: "10-25mln", label: "10-25 mln" },
          { value: "25-50mln", label: "25-50 mln" },
          { value: "50mln+", label: "50 mln+" },
        ]}
      />

      <div className="button-group">
        <button
          type="button"
          className="button button--secondary"
          onClick={onPrev}
        >
          Wstecz
        </button>
        <button
          type="button"
          className="button button--primary"
          onClick={onNext}
        >
          Następny
        </button>
      </div>
    </div>
  );
};

export default FormPageTwo;
