import React from "react";
import { useField } from "formik";
import "./FormPages.css";

interface FormPageThreeProps {
  onPrev: () => void;
  isSubmitting: boolean;
}

const FormPageThree: React.FC<FormPageThreeProps> = ({
  onPrev,
  isSubmitting,
}) => {
  const [clientField, clientMeta] = useField("client_looking_for");
  const [supplierField, supplierMeta] = useField("supplier_looking_for");

  return (
    <div className="form-page" id="page-3">
      <h2 className="text-lg leading-[1.2]">Kogo szukasz:</h2>

      <div className="form-group">
        <label htmlFor="client_looking_for">Kogo szukam jako klienta</label>
        <div className="input-container">
          <textarea
            className="form-control"
            id="client_looking_for"
            placeholder="Kogo szukam jako klienta"
            {...clientField}
          ></textarea>
        </div>
        {clientMeta.touched && clientMeta.error ? (
          <div className="validation-response">{clientMeta.error}</div>
        ) : null}
      </div>

      <div className="form-group">
        <label htmlFor="supplier_looking_for">Kogo szukam jako dostawcy</label>
        <div className="input-container">
          <textarea
            className="form-control"
            id="supplier_looking_for"
            placeholder="Kogo szukam jako dostawcy"
            {...supplierField}
          ></textarea>
        </div>
        {supplierMeta.touched && supplierMeta.error ? (
          <div className="validation-response">{supplierMeta.error}</div>
        ) : null}
      </div>

      <div className="button-group">
        <button
          type="button"
          className="button button--secondary"
          onClick={onPrev}
        >
          Wstecz
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="button button--primary btn-order register-payment"
        >
          Wyślij
        </button>
      </div>
    </div>
  );
};

export default FormPageThree;
